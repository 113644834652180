import React, {useMemo} from 'react'
import {useTable, useGlobalFilter,useSortBy, usePagination, useExpanded } from 'react-table';

// import StockAPI from "../StockAPI.json"
import { MockDocTableColumn } from './Columns'; 
import { FaSortDown, FaSortUp } from "react-icons/fa";
import PerfectScrollbar from "react-perfect-scrollbar";


const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};

export const InvoiceDetailTable = (invoicedata) => {
    const columns = useMemo(()=> MockDocTableColumn(invoicedata.diamonddetail,invoicedata.language,invoicedata.userinfo),[] )
    const data = useMemo(()=> invoicedata.diamonddetail,[invoicedata.diamonddetail])

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
    } = useTable({
        columns,
        data,
        initialState: { pageSize: 1000 }
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
       
    )
    return (
        <>
            <div className="table-responsive w-100 bg-white">
                <PerfectScrollbar
                    options={perfectScrollbarOptions}
                    className="scroll border-bottom"
                    style={{ maxHeight: "342px", position: "relative" }}
                >
                    <table className="table table-hover" {...getTableProps()}>
                        <thead className="custom_datatable_head sticky-top" >
                            {headerGroups.map(headerGroup =>(
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    { headerGroup.headers.map((column)=>(
                                        <th className="text-center align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span className="text-secondary">
                                                {column.isSorted ? (column.isSortedDesc ? <FaSortDown /> : <FaSortUp />): ''}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.length > 0 && page.map((row) => {
                                prepareRow(row)
                                return(
                                    <>
                                        <tr {...row.getRowProps()} key={row.id}>
                                            {row.cells.map((cell,i) => {
                                                return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                            })}
                                        </tr>
                                    </>
                                )
                            }) }
                        </tbody>
                    </table>
                </PerfectScrollbar>
                <div className="text-primary font-weight-bold mt-4 font-italic pl-2 pb-2" >{page.length > 4 ? "Scroll for explore all diamonds":""}</div>
                
            </div>
        </>
    )
}