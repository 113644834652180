import React ,{useState,useEffect} from "react";
import { at } from "lodash";
import { useField } from "formik";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
} from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
export default function DiamTypeCheck(props) {
    const [warnMsg,setWarnMsg] = useState('')
    const { label, ...rest } = props;
    const [field, meta, helper] = useField(props);
    // console.log(meta.error,"ERRORRRRRMETA")
    const [border,setBorder] = useState("")
    useEffect(() => {
        if(props.values && (props.values.naturaldiamond || props.values.naturalfancydiamond || props.values.lgwhitediam || props.values.lgfancydiam)){
            setBorder("")

        }else{
            setBorder("border-danger")
        }
    },[props.values])
    const { setValue } = helper;
    function _renderHelperText() {
        const [touched, error] = at(meta, "touched", "error");
        if (touched && error) {
            return <FormHelperText>{error}</FormHelperText>;
        }
    }

    function _onChange(e) {
    setWarnMsg('')
    if(props.values[`${props.name}`]){
    props.setFieldValue(props.name,'')
    }
    else{
    props.setFieldValue(props.name,props.name)

    }
    props.setSelectAllFields(false)
    props.setSelectAllFieldsLab(false)
    if(props.name === "naturaldiamond"){
        props.setValues({
      "rulename": props.values.rulename,
      "rule_id":props.values.rule_id || '',
      "naturaldiamond": 1,
      "naturalfancydiamond": '',
      "lgwhitediam": '',
      "lgfancydiam": '',
      "diamondshape": [],
      "carat_from": '0.18',
      "carat_to": '100.00',
      "diamondcolor": [],
      "diamondfancy_color": [],
      "diamondfancy_intensity": [],
      "diamondfancy_overtone": [],
      "diamondclarity": [],
      "lab_val": [],
      "cuts": [],
      "diamondflour": [],
      "polishes": [],
      "symmetries": [],
      "diamondshade": [],
      "milky": [],
      "eyeclean": [],
      "price_from": '',
      "price_to": '',
      "total_price_from": 0,
      "total_price_to": 999999,
      "media": [],


      "lab_shape": [],
      "lab_carat_from": '0.18',
      "lab_carat_to": '100.00',
      "lab_color": [],
      "lab_fancy_color": [],
      "lab_fancy_intensity": [],
      "lab_fancy_overtone": [],
      "lab_clarity": [],
      "lab_lab": [],
      "lab_cuts": [],
      "lab_polishes": [],
      "lab_symmetries": [],
      "lab_flourselect": [],
      "lab_shadeselect": [],
      "lab_milky": [],
      "lab_eyeclean": [],
      "lab_min_dollarperct": '',
      "lab_max_dollarperct": '',
      "lab_total_price_from": 0,
      "lab_total_price_to": 999999,
      "lab_all_media": [],


        // [muti_currency": 'INR',
        
      "dbfetch":false,
      "markupvalue":'',
      "markupoption":'',
      "suppliers":[],
      "multi_currency":props.location.state.currencyvalue?props.location.state.currencyvalue:props.values.multi_currency,
      "brand":[],
      "origin":[],
      "treatment":[],
      "keytosymbol":[],
      "labbrand":[],
      "laborigin":[],
      "labtreatment":[],
      "labkeytosymbol":[],
      "caratpricemarkups":[],
      "availability":[],
      "labavailability":[],
      "diamondlocation":[],
      "diamondlablocation":[]
      })
    }
    if(props.name === "naturalfancydiamond"){
        props.setValues({
            "rulename": props.values.rulename,
            "rule_id":props.values.rule_id || '',
            "naturaldiamond": '',
            "naturalfancydiamond": 1,
            "lgwhitediam": '',
            "lgfancydiam": '',
            "diamondshape": [],
            "carat_from": '0.18',
            "carat_to": '100.00',
            "diamondcolor": [],
            "diamondfancy_color": [],
            "diamondfancy_intensity": [],
            "diamondfancy_overtone": [],
            "diamondclarity": [],
            "lab_val": [],
            "cuts": [],
            "diamondflour": [],
            "polishes": [],
            "symmetries": [],
            "diamondshade": [],
            "milky": [],
            "eyeclean": [],
            "price_from": '',
            "price_to": '',
            "total_price_from": 0,
            "total_price_to": 999999,
            "media": [],
      
      
            "lab_shape": [],
            "lab_carat_from": '0.18',
            "lab_carat_to": '100.00',
            "lab_color": [],
            "lab_fancy_color": [],
            "lab_fancy_intensity": [],
            "lab_fancy_overtone": [],
            "lab_clarity": [],
            "lab_lab": [],
            "lab_cuts": [],
            "lab_polishes": [],
            "lab_symmetries": [],
            "lab_flourselect": [],
            "lab_shadeselect": [],
            "lab_milky": [],
            "lab_eyeclean": [],
            "lab_min_dollarperct": '',
            "lab_max_dollarperct": '',
            "lab_total_price_from": 0,
            "lab_total_price_to": 999999,
            "lab_all_media": [],
      
      
              // [muti_currency": 'INR',
              
            "dbfetch":false,
            "markupvalue":'',
            "markupoption":'',
            "suppliers":[],
            "multi_currency":props.location.state.currencyvalue?props.location.state.currencyvalue:props.values.multi_currency,
            "brand":[],
            "origin":[],
            "treatment":[],
            "keytosymbol":[],
            "labbrand":[],
            "laborigin":[],
            "labtreatment":[],
            "labkeytosymbol":[],
            "caratpricemarkups":[],
            "availability":[],
            "labavailability":[],
            "diamondlocation":[],
            "diamondlablocation":[]
            })
    }
    if(props.name === "lgwhitediam"){
        props.setValues({
            "rulename": props.values.rulename,
            "rule_id":props.values.rule_id || '',
            "naturaldiamond": '',
            "naturalfancydiamond": '',
            "lgwhitediam": 1,
            "lgfancydiam": '',
            "diamondshape": [],
            "carat_from": '0.18',
            "carat_to": '100.00',
            "diamondcolor": [],
            "diamondfancy_color": [],
            "diamondfancy_intensity": [],
            "diamondfancy_overtone": [],
            "diamondclarity": [],
            "lab_val": [],
            "cuts": [],
            "diamondflour": [],
            "polishes": [],
            "symmetries": [],
            "diamondshade": [],
            "milky": [],
            "eyeclean": [],
            "price_from": '',
            "price_to": '',
            "total_price_from": 0,
            "total_price_to": 999999,
            "media": [],
      
      
            "lab_shape": [],
            "lab_carat_from": '0.18',
            "lab_carat_to": '100.00',
            "lab_color": [],
            "lab_fancy_color": [],
            "lab_fancy_intensity": [],
            "lab_fancy_overtone": [],
            "lab_clarity": [],
            "lab_lab": [],
            "lab_cuts": [],
            "lab_polishes": [],
            "lab_symmetries": [],
            "lab_flourselect": [],
            "lab_shadeselect": [],
            "lab_milky": [],
            "lab_eyeclean": [],
            "lab_min_dollarperct": '',
            "lab_max_dollarperct": '',
            "lab_total_price_from": 0,
            "lab_total_price_to": 999999,
            "lab_all_media": [],
      
      
              // [muti_currency": 'INR',
              
            "dbfetch":false,
            "markupvalue":'',
            "markupoption":'',
            "suppliers":[],
            "multi_currency":props.location.state.currencyvalue?props.location.state.currencyvalue:props.values.multi_currency,
            "brand":[],
            "origin":[],
            "treatment":[],
            "keytosymbol":[],
            "labbrand":[],
            "laborigin":[],
            "labtreatment":[],
            "labkeytosymbol":[],
            "caratpricemarkups":[],
            "availability":[],
            "labavailability":[],
            "diamondlocation":[],
            "diamondlablocation":[]
            })
    }
    if(props.name === "lgfancydiam"){
        props.setValues({
            "rulename": props.values.rulename,
            "rule_id":props.values.rule_id || '',
            "naturaldiamond": '',
            "naturalfancydiamond": '',
            "lgwhitediam": '',
            "lgfancydiam": 1,
            "diamondshape": [],
            "carat_from": '0.18',
            "carat_to": '100.00',
            "diamondcolor": [],
            "diamondfancy_color": [],
            "diamondfancy_intensity": [],
            "diamondfancy_overtone": [],
            "diamondclarity": [],
            "lab_val": [],
            "cuts": [],
            "diamondflour": [],
            "polishes": [],
            "symmetries": [],
            "diamondshade": [],
            "milky": [],
            "eyeclean": [],
            "price_from": '',
            "price_to": '',
            "total_price_from": 0,
            "total_price_to": 999999,
            "media": [],
      
      
            "lab_shape": [],
            "lab_carat_from": '0.18',
            "lab_carat_to": '100.00',
            "lab_color": [],
            "lab_fancy_color": [],
            "lab_fancy_intensity": [],
            "lab_fancy_overtone": [],
            "lab_clarity": [],
            "lab_lab": [],
            "lab_cuts": [],
            "lab_polishes": [],
            "lab_symmetries": [],
            "lab_flourselect": [],
            "lab_shadeselect": [],
            "lab_milky": [],
            "lab_eyeclean": [],
            "lab_min_dollarperct": '',
            "lab_max_dollarperct": '',
            "lab_total_price_from": 0,
            "lab_total_price_to": 999999,
            "lab_all_media": [],
      
      
              // [muti_currency": 'INR',
              
            "dbfetch":false,
            "markupvalue":'',
            "markupoption":'',
            "suppliers":[],
            "multi_currency":props.location.state.currencyvalue?props.location.state.currencyvalue:props.values.multi_currency,
            "brand":[],
            "origin":[],
            "treatment":[],
            "keytosymbol":[],
            "labbrand":[],
            "laborigin":[],
            "labtreatment":[],
            "labkeytosymbol":[],
            "caratpricemarkups":[],
            "availability":[],
            "labavailability":[],
            "diamondlocation":[],
            "diamondlablocation":[]
            })
    }
    }
    return (
       <>
       {warnMsg && (
                <SweetAlert
                    warning
                    title={"Warning!"}
                    onConfirm={()=>_onChange()}
                    onCancel={()=>setWarnMsg(false)}
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="primary"
                    cancelBtnBsStyle="danger"
                >
                    {warnMsg}
                </SweetAlert>
            )} 
       <label className="table_cell_checkmark1 multitab_diam " >
            <div className={`text-center mt-2 multitab_diam_box naturaldiamond radio ${border}`}>
              <div
                  className={`${
                      props.name === "naturaldiamond" ? "white_dia_icon" 
                      : props.name === "naturalfancydiamond" ? "fancy_dia_icon" 
                      : props.name === "lgwhitediam" ? "lab_dia_icon" 
                      : props.name === "lgfancydiam" ? "lab_fancy_dia_icon" : ""
                    }`}
                ></div>
                <input type="radio" name={props.name} value={field.checked} id={props.name} className="check_box_all"  checked={meta.value} onChange={(e) => {
                    if(window.location.pathname === "/create-rule"){
                        _onChange()
                    }
                    else{
                        setWarnMsg('Are you sure you want to change diamond type ? (Please Note : once you change diamond type your rule will reset.)')
                    }
                }} error={meta.touched && meta.error && true}/>
                <span className="checkmark1"></span>
                <p className="m-0 mt-2">{label}</p>
            </div>
        </label>
       </>
    );
}
