import React, { useState, useEffect } from "react";
import { Modal, Button, Form,OverlayTrigger,Tooltip } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_helpers";
import axios from "axios";
import { NODE_API_URL,BASE_URL, IVPCURRENCY } from "../../../env_config";
import { useHistory } from "react-router-dom";
import { SwalSuccess } from "./";
import { Backdrop,CircularProgress} from "@material-ui/core";
// import { makeStyles , Radio, RadioGroRadioup, FormControlLabel, FormControl, FormLabel} from '@material-ui/core';
// import {OverlayTrigger,Tooltip,Overlay} from "react-bootstrap";
import { FaEdit} from "react-icons/fa";
import Cookies from "js-cookie"
import SweetAlert from "react-bootstrap-sweetalert";
import getSymbolFromCurrency from "currency-symbol-map";

// import PerfectScrollbar from "react-perfect-scrollbar";
// const perfectScrollbarOptions = {
//     wheelSpeed: 1,
//     wheelPropagation: false,
// };

export const ConfirmGoodsParcel = (props) => {
    const [showConfirm, setShowConfirm] = useState(false);
    const [swalSuccessOpen,setSwalSuccessOpen] = useState(false)
    const [swalErrorOpen,setSwalErrorOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const [buyType, setBuyType] = React.useState('carat');
    const [loading, setLoading] = React.useState(false);
    const [isSubmitting ,SetIsSubmitting] = useState(false)
    const [openLoader, setOpenLoader] = React.useState(false);
    const [comment,SetComment] = useState()
    const [currencyRate, setCurrencyRate] = useState("1"); 
    const [currencySymbol, setCurrencySymbol] = useState("");
    // const [refe,setRefe] = useState()
    // const [addRef,setAddRef] = useState(false)
    const [data , setData] =useState([])
    const [inputValue, setInputValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    // const [ip,setIp] = React.useState()
    const enableLoading = () => {
        setLoading(true);
        setOpenLoader(true);
        SetIsSubmitting(true)
    };
    const disableLoading = () => {
        setLoading(false);
        setOpenLoader(false);
        SetIsSubmitting(false)
    };
// console.log("props.diamondselected",data)
// data.map(value => {console.log("datra",value.original)})

    const history = useHistory()
    const [ip,setIp] = React.useState()
    useEffect(() => {
        setData([props.diamondselected][0].data === undefined ? []:[props.diamondselected][0].data)
        SetComment(null)
        setShowConfirm(props.show)
        axios({
                method: 'GET',
                url:"https://api.ipify.org",
            })
            .then(function (res) {
                // setIpdata({query:res.data})
                if(res && res.data){
                    setIp(res.data)                    
                }
            })
            .catch((res) => {
            });
    },[props.diamondselected,props.show])
    const closeModel = () => {
        props.onHide()
        setShowConfirm(false)
    }
   
    const swalErrorOpenfn = (props) => {
        closeModel()
        setSwalErrorOpen(true)
    }
    
    const swalSuccessOpenfn = (props) => {
        closeModel()
        setSwalSuccessOpen(true)
    }

    function handleChange(event) {
        setBuyType(event.target.value);
    }

    const handleInputChange = (event,parceldata,index) => {
        const value = event.target.value;
        // console.log(value.length)
        setInputValue(value);
        // setRefe(value)
        if (value.length > 29) {
          setErrorMessage('String length should not exceed 30 characters.');
        } else {
          setErrorMessage('');
        }
        let parceld = [...parceldata]
        parceld[index] = {...parceld[index],client_ref:event.target.value}
        // console.log(parceld,"parceld")
        setData(parceld)
    };
    useEffect(() => {

        axios({
            method: "POST",
            url: IVPCURRENCY,
            headers: { "Content-Type": "application/json" },
        })
        .then(response => {
            if (response && response.data && response.data.data.length > 0) {
                const currencyKey = `cur_${props.userinfo.cb_global_curr.toLowerCase()}`;
                const rate = response.data.data[0][currencyKey];

                if (rate) {
                    setCurrencyRate(rate);

                    const symbol = getSymbolFromCurrency(props.userinfo.cb_global_curr);
                    setCurrencySymbol(symbol || "");

                } else {
                    setCurrencyRate("1");
                    setCurrencySymbol("");
                }
            }
        })
        .catch(error => {
            console.error("Error fetching currency data:", error);
            setCurrencyRate("1");
            setCurrencySymbol("");
        });
    }, [props.userinfo.cb_global_curr]);
    const ConfirmDiamonds = (props) => {

        let diamondinfo = data.map(value => {
            return {
                "carat": value.original.carat,
                "avgweight": value.original.avgweight,
                "dollarPerCarat": value.original.dollarPerCarat,
                "sumprice":value.original.sumprice,
                "totalPrice": value.original.totalPrice,
                "sieve_from": value.original.sieve_from,
                "sieve_to": value.original.sieve_to,
                "length_mm": value.original.mm_from,
                "width_mm": value.original.mm_to,
                "clarity": value.original.clarities,
                "color": value.original.color,
                "pieces": value.original.pcs,
                "diamond_type": value.original.diamond_type ? value.original.diamond_type : props.dimondType,
                "C_Shape": value.original.c_Shape || value.original.C_Shape || "-",
                "type":value.original.type,
                "parcelId": value.original.parcel_id ? value.original.parcel_id : 1,
                "client_ref":value.client_ref
            }
        })
        const finalData = {
            "user_id":props.userinfo.id,
            "diamond_info":diamondinfo,
            "ipaddress":ip,
            "customer_comment":comment,
        }
        // console.log(finalData,"finalData")
        enableLoading()
        axios({
            method: "POST",
            url: `${NODE_API_URL}/INTL/buy-diamond`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
            data: finalData,
        }).then(res => {
            disableLoading()
            if (res.data.success === false){
                swalErrorOpenfn()
                setAlertMessage(res.data.message)
            }
            if(res && res.data && res.data.success){
               
                setAlertMessage(res.data.message)
                    if(res.data.success === true){
                        swalSuccessOpenfn()
                        reDirectToConfirm()
                    } else if (res.data.success === false){
                        swalErrorOpenfn()
                    }
            }
        }).catch(error => {
            if(error?.response?.data?.message === "UnAuthorized!"){
              var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
              if(cookies[i] && !cookies[i].includes("view")){
                var equals = cookies[i].indexOf("=");
                var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
              }
            }
            
            window.location.replace(`${BASE_URL}/logout`);
          }
          })
        
    }

    const reDirectToConfirm = () => {
        history.push({
            pathname: `/confirmgoods`
        })
    }
    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const AddReference = (parceldata,index) => {
        let parceld = [...parceldata]
        parceld[index] = {...parceld[index],addref:true}
        setData(parceld)
    }

    return (
        <div>
            <Modal
                show={showConfirm}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                size="xl"
            >
                <Modal.Header>
                    <Modal.Title>{props.language.CONFIRM_GOODS_POPUP_CONFIRM_DIAMOND}</Modal.Title>
                    <div className="d-flex">
                        <div className=" font-weight-bold font-size-lg d-flex mr-4">
                           {props.userinfo.cb_global_curr} Rate: <div className="ml-2">{currencySymbol}{currencyRate}</div >
                        </div>
                        <button type="button" className="close" onClick={() => closeModel(props)}><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 d-flex justify-content-between mb-3">
                            <span>{props.language.CONFIRM_GOODS_POPUP_CONFIRM_DIAMOND} : {data.length}</span>
                           
                        </div>
                        <div className="col-12">
                            {/* <PerfectScrollbar
                                options={perfectScrollbarOptions}
                                className="scroll border-bottom"
                                
                            > */}
                                <div className="custom-scroll table-responsive" style={{ maxHeight: "322px", position: "relative" }}>
                                    <table className="table border-bottom">
                                        <thead>
                                            <tr className="bg-gray-100 sticky-top">
                                                <th className="align-middle">{props.language.CONFIRM_GOODS_POPUP_DIAMOND_DETAILS}</th>
                                                <th className="text-center text-nowrap align-middle">
                                                    Size(MM) - Sieve
                                                </th>
                                                <th className="text-center py-3 text-nowrap  align-middle">{props.language.CONFIRM_GOODS_POPUP_DIAMOND_LOCATION} <br /> {props.language.CONFIRM_GOODS_POPUP_DIAMOND_SHIPPING_DAYS}</th>
                                                <th align="center" className="text-right text-nowrap align-middle">
                                                    {props.language.CONFIRM_GOODS_POPUP_OURPRICEUSD} <br />
                                                    USD/CT
                                                </th>
                                                {props.userinfo?.cb_global_curr && props.userinfo?.cb_global_curr !== "USD" ?
                                                <th align="center" className="text-right py-3">
                                                    Our Price ({props.userinfo?.cb_global_curr}) <br />
                                                    {currencySymbol}/CT
                                                </th> 
                                            : null}
                                                <th className="text-center  py-3 align-middle">
                                                    Client <br />
                                                    Reference
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {data.length !== 0 ? data.map((value,index) => (
                                            <tr className={`position-relative  align-middle ${value.original.parcel_id ? "bg-warning-o-20" : "bg-primary-o-20"}`} key={index}>
                                                <td className=" align-middle">
                                                    <div className={`d-flex m-0`}>
                                                        <div className="text-center p-0">
                                                            <img
                                                                className="mr-2"
                                                                alt={value.original.c_Shape || value.original.C_Shape || "-"}
                                                                src={toAbsoluteUrl(`/media/shape/svg_shape/parcel/${value.original.c_Shape.toLowerCase() || value.original.C_Shape.toLowerCase() || "-"}.svg`)}
                                                                width="50px"
                                                                onError={e => {
                                                                    e.currentTarget.src = toAbsoluteUrl(
                                                                        `/media/shape/svg_shape/parcel/other.svg`
                                                                        )
                                                                    }}
                                                            />
                                                        </div>
                                                        <div className="pl-2 p-0">
                                                            <div className="mb-1">
                                                                <span
                                                                    className={`${value.original.diamond_type ? value.original.diamond_type : props.dimondType}_diamond mr-3 small`}
                                                                >
                                                                    {value.original.diamond_type ? value.original.diamond_type : props.dimondType}
                                                                </span>
                                                                <span className="small mr-2">
                                                                    {value.original.parcel_id ?  `${value.original.diamond_type}-${value.original.parcel_id}` : "New"}
                                                                </span>
                                                                <span className="small">
                                                                    Order By : {value.original.type}
                                                                </span>
                                                            </div>
        
                                                            <span className="text-dark font-weight-bold d-block">
                                                                {value.original.c_Shape || value.original.C_Shape || "-"}{" "}
                                                                {parseFloat(value.original.carat).toFixed(2)}{"Ct. "}
                                                                {parseFloat(value.original.pcs).toFixed(2)}{"Pcs. "}
                                                                {value.original.color}{" "}
                                                                {value.original.clarities}{" "}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className={`text-center text-nowrap  align-middle`}>
                                                    <div className="">{value.original.mm_from} to {value.original.mm_to} MM</div>
                                                    {value.original.byId ? null :
                                                        <div className="text-muted small">+{value.original.sieve_from} to -{value.original.sieve_to}</div>
                                                    }
                                                </td> 
                                                <td className={`text-center align-middle`}>
                                                    India <br />
                                                    {value.original.shipping_days ? value.original.shipping_days : "7"} Days
                                                </td> 
                                                <td nowrap className={`text-right align-middle`}>
                                                    ${numberWithCommas(parseFloat(value.original.totalPrice))}
                                                    <br/>
                                                    <span className="text-muted small ">USD/CT {numberWithCommas(parseFloat(value.original.dollarPerCarat))}</span>
                                                </td>
                                                {props.userinfo?.cb_global_curr && props.userinfo?.cb_global_curr !== "USD" ?
                                                <td align="center" className="text-right font-weight-bold py-3">
                                                    {currencySymbol}{numberWithCommas(value.original.totalPrice * parseFloat(currencyRate))} <br />
                                                    <span className="small text-muted">{currencySymbol}/CT {numberWithCommas(value.original.dollarPerCarat * parseFloat(currencyRate))}</span>
                                                </td> 
                                            : null}
                                                <td className={`text-center align-middle`}>
                                                    {value.addref  ? 
                                                        <input type="text" className="form-control" style={{width:"100px"}} onChange={(e) => handleInputChange(e,data,index)} maxLength={30} placeholder="Please enter reference"/>
                                                    :
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id="tooltip">
                                                                    Click to add Reference
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <button className="btn btn-icon btn-outline-primary btn-sm" onClick={()=> AddReference(data,index)}><FaEdit className="font-size-h5"/></button>
                                                        </OverlayTrigger>
                                                    }
                                                </td>
                                            </tr>
                                        )) : <tr> <td className="text-center" colSpan="100%">No Stones Selected</td></tr>}
                                        </tbody>
                                    </table>
                                </div>
                            {/* </PerfectScrollbar> */}
                            { data.length > 4 ? <div className="text-left">Scroll for explore more</div> : ""}
                            {errorMessage && <p className="mb-1 text-danger">{errorMessage}</p>}
                            <div className="row mt-5">
                                <div className="col-12 font-size-h6 text-right">
                                    Per USD/CT Price : ${numberWithCommas(parseFloat(props.totalPrice/props.totalCarat))}
                                </div>
                                <div className="col-12 font-size-h6 mt-2 text-right font-weight-bold">
                                    Total USD : ${numberWithCommas(parseFloat(props.totalPrice))}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Form.Group controlId="Notes">
                                        <Form.Label>
                                            {props.language.CONFIRM_GOODS_POPUP_NOTES}:
                                        </Form.Label>
                                        <Form.Control as="textarea" rows="3" onChange={(e) => SetComment(e.target.value)} maxLength={256} placeholder="Please enter your notes if any"/>
                                    </Form.Group>
                                    <span className="p-2">255 Characters Remaining</span>
                                </div>
                            </div>
                            {/* <pre>{JSON.stringify(props)}</pre> */}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-between py-3">
                    {props.conversionrate !== 1?<span className="font-weight-bold"> All Prices are under USD</span>:""}
                    <div>
                        <Button variant="secondary" className="mr-2 font-weight-bold" onClick={() => closeModel(props)}>
                            {props.language.CONFIRM_GOODS_POPUP_CLOSE}
                        </Button>
                        <Button disabled={isSubmitting} className="px-8 font-weight-bold" variant="primary" onClick={() => ConfirmDiamonds(props)}>
                        {props.language.CONFIRM_GOODS_POPUP_CONFIRM_ORDER}
                            {loading && <span className="ml-1 spinner spinner-white"></span>}
                            <Backdrop
                                sx={{ color: '#002173', zIndex: (theme) => theme.zIndex.drawer + 5 }}
                                open={openLoader}
                                // onClick={handleCloseLoader}
                            >
                                <CircularProgress color="secondary" />
                            </Backdrop>
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <SwalSuccess show={swalSuccessOpen} message={alertMessage} onClose={() => setSwalSuccessOpen(false)} responseOnOk={reDirectToConfirm}/> 
            {/* <SwalError show={swalErrorOpen} message={alertMessage} onClose={() => setSwalErrorOpen(false)} closeBtn={true}/>  */}
            { props.userinfo.kyc_status === "KYC_REJ" || props.userinfo.kyc_status === "NO_KYC"? (
            <>
                {swalErrorOpen && (
                    <SweetAlert
                        error
                        // title="Warning"
                        onConfirm={()=>history.push('/profile')}
                        confirmBtnText={props.userinfo.kyc_status === "NO_KYC" ? "Complete Profile" : "Revise Profile"}
                        confirmBtnBsStyle="primary"
                        showCancel
                        onCancel={() => setSwalErrorOpen(false)}
                        cancelBtnText="close"
                        cancelBtnBsStyle="secondary"
                    >
                       <span className="d-block mb-2 font-weight-bold">{alertMessage}</span>
                    </SweetAlert>
                )} 
            </>):(
                <>
                    {swalErrorOpen && (
                        <SweetAlert
                            error
                            // title="Warning"
                            onConfirm={() => setSwalErrorOpen(false)}
                            confirmBtnText="close"
                            confirmBtnBsStyle="primary"
                        >
                           <span className="d-block mb-2 font-weight-bold">{alertMessage}</span>
                        </SweetAlert>
                    )} 
                </>
            )}
        </div>
    );
};
