import React, { useState,useEffect } from 'react';
import { useHistory,useLocation } from 'react-router-dom';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Grid,
  CircularProgress,
  withStyles
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import axios from "axios"
import DimondType from './Forms/DimondType';
import CreateRules from './Forms/CreateRules';
import NaturalDiam from './Forms/NaturalDiam';
import LabDiam from './Forms/LabDiam';
import ColorMarkup from './Forms/ColorMarkup';
import SupplierPage from './Forms/SupplierPage';
import FormSuccess from './FormSuccess/FormSuccess';
import { NODE_API_URL,BASE_URL } from '../../../../env_config';
import Cookies from "js-cookie";
import ValidationSchema from './FormModel/validationSchema';
import kycFormModel from './FormModel/kycFormModel';
import {fetchData} from './FormModel/forminitialValuesCopy';
import { FaChevronLeft } from 'react-icons/fa';
import useStyles from './styles';
import { ToastContainer, toast } from 'react-toastify';
import red from "material-ui/colors/red";
const steps = ["Update Rule",'Choose Diamond', 'Diamond Parameters', 'Price Markup'];
const { formId, formField } = kycFormModel;

function _renderStepContent(step,setFieldValue,prev,setPrev,values,userinfo,selectallfields,selectallfieldslab,language,setValues,location,setSelectAllFields,setSelectAllFieldsLab,setSupplierChanged,setSupplierData,supplierdata,updateapi,setValidMarkup,setAllSupplierData) {
  switch (step) {
    case 0:
      return <CreateRules formField={formField} values={values} setFieldValue={setFieldValue} userinfo={userinfo} language={language}/>;;
    case 1:
      return <DimondType formField={formField} setFieldValue={setFieldValue} prev={prev} setPrev={setPrev} values={values} language={language} setValues={setValues} location={location} setSelectAllFields={setSelectAllFields} setSelectAllFieldsLab={setSelectAllFieldsLab}/>;
    case 2:
      return <>{(values.naturaldiamond || values.naturalfancydiamond)?<NaturalDiam setFieldValue={setFieldValue} formField={formField} values={values} selectallfields={selectallfields} language={language} userinfo={userinfo} setSelectAllFields={setSelectAllFields}/>:<LabDiam setFieldValue={setFieldValue} formField={formField} values={values} selectallfields={selectallfieldslab} language={language} userinfo={userinfo} setSelectAllFields={setSelectAllFieldsLab}/>}</>;
    case 3:
        return <ColorMarkup formField={formField} values={values} setFieldValue={setFieldValue} userinfo={userinfo} language={language} setValidMarkup={setValidMarkup}/>;
    default:
      return <div>Not Found</div>;
  }
}

export default function CCModeCopyForm(props) {
  const ErrorButton = withStyles(theme => ({

    root: {

      color: theme.palette.getContrastText(red[500]),

      backgroundColor: red[500],

      '&:hover': {

        backgroundColor: red[700],

      },

    },

  }))(Button);
    const location = useLocation()
    const formikRef = React.useRef(null);
    const history = useHistory()
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [initialValues,setInitialValues] = useState({})
  const [customerapi,setCustomerAPI] = useState([])
  const [allrules,setAllRules] = useState([])
  const [supplierchanged,setSupplierChanged] = useState(false)
  const [supplierdata,setSupplierData] = useState([])
  const [updateapi,setupdateApi] = useState(false)
  const [validmarkup,setValidMarkup] = useState(false)
  const [allsupplierdata,setAllSupplierData] = useState([])
  useEffect(() => {
    fetchData(props.userinfo,location).then(values => {
      // console.log(values,"fetchData")
      setInitialValues(values)
      axios({
        method: "get",
        url: `${NODE_API_URL}/INTL/fetch-ccmode-rules`,
        headers: {
            "Content-Type": "application/json",
             "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
        }
    }).then(apireq => {
        if(apireq && apireq.data && apireq.data.data && apireq.data.data.length){
            // console.log(apireq.data.data,"APIIIIIII")
            let mapdata = []
            if(uservalues.naturaldiamond){
              mapdata = apireq.data.data.filter(val => val.naturaldiamond && val.naturaldiamond.toString() === uservalues.naturaldiamond.toString())
            }
            if(uservalues.naturalfancydiamond){
              mapdata = apireq.data.data.filter(val => val.naturalfancydiamond && val.naturalfancydiamond.toString() === uservalues.naturalfancydiamond.toString())
            }
            if(uservalues.lgwhitediam){
              mapdata = apireq.data.data.filter(val => val.labdiamond && val.labdiamond.toString() === uservalues.lgwhitediam.toString())
            }
            if(uservalues.lgfancydiam){
              mapdata = apireq.data.data.filter(val => val.labfancydiamond && val.labfancydiamond.toString() === uservalues.lgfancydiam.toString())
            }
            console.log(mapdata,"mapdatamapdata")
            mapdata = mapdata.map(value => {
              return {
                rule_name:value.rule_name,//remove if overlap not working
                shape:value.shape,
                cut:value.cut,
                clarity:value.clarity,
                color:value.color,
                min_carat:value.min_carat,
                max_carat:value.max_carat,
                lab:value.lab,
                symmetry:value.symmetry,
                fluorescence:value.fluorescence,
                polish:value.polish,
                milky:value.milky,
                eyeclean:value.eyeclean,
                shade:value.shade,
                min_dollarperct:value.min_dollarperct,
                max_dollarperct:value.max_dollarperct,
                total_price_from:value.total_price_from,
                total_price_to:value.total_price_to,
                media:value.media,
                minlength:value.minlength,
                maxlength:value.maxlength,
                minwidth:value.minwidth,
                maxwidth:value.maxwidth,
                minheight:value.minheight,
                maxheight:value.maxheight,
                tablefrom:value.tablefrom,
                tableto:value.tableto,
                depthfrom:value.depthfrom,
                depthto:value.depthto,
                ratiofrom:value.ratiofrom,
                ratioto:value.ratioto,
                crheightmin:value.crheightmin,
                crheightmax:value.crheightmax,
                cranglemin:value.cranglemin,
                cranglemax:value.cranglemax,
                pavheightmin:value.pavheightmin,
                pavheightmax:value.pavheightmax,
                pavanglemin:value.pavanglemin,
                pavanglemax:value.pavanglemax,
                brand:value.brand,
                origin:value.origin,
                treatment:value.treatment,
                keytosymbol:value.keytosymbol,
                diamondfancy_color:value.diamondfancy_color,
                diamondfancy_intensity:value.diamondfancy_intensity,
                diamondfancy_overtone:value.diamondfancy_overtone,
  
                lab_shape:value.lab_shape,
                lab_cut:value.lab_cut,
                lab_clarity:value.lab_clarity,
                lab_color:value.lab_color,
                lab_min_carat:value.lab_min_carat,
                lab_max_carat:value.lab_max_carat,
                lab_lab:value.lab_lab,
                lab_symmetry:value.lab_symmetry,
                lab_fluorescence:value.lab_fluorescence,
                lab_polish:value.lab_polish,
                lab_milky:value.lab_milky,
                lab_eyeclean:value.lab_eyeclean,
                lab_shade:value.lab_shade,
                lab_min_dollarperct:value.lab_min_dollarperct,
                lab_max_dollarperct:value.lab_max_dollarperct,
                lab_total_price_from:value.lab_total_price_from,
                lab_total_price_to:value.lab_total_price_to,
                lab_media:value.lab_media,
                labminlength:value.labminlength,
                labmaxlength:value.labmaxlength,
                labminwidth:value.labminwidth,
                labmaxwidth:value.labmaxwidth,
                labminheight:value.labminheight,
                labmaxheight:value.labmaxheight,
                labtablemin:value.labtablemin,
                labtablemax:value.labtablemax,
                labdepthmin:value.labdepthmin,
                labdepthmax:value.labdepthmax,
                labratiomin:value.labratiomin,
                labratiomax:value.labratiomax,
                labcrheightmin:value.labcrheightmin,
                labcrheightmax:value.labcrheightmax,
                labcranglemin:value.labcranglemin,
                labcranglemax:value.labcranglemax,
                labpavheightmin:value.labpavheightmin,
                labpavheightmax:value.labpavheightmax,
                labpavanglemin:value.labpavanglemin,
                labpavanglemax:value.labpavanglemax,
                laborigin:value.laborigin,
                labtreatment:value.labtreatment,
                labkeytosymbol:value.labkeytosymbol,
                // keytosymbol:value.keytosymbol,
                lab_fancy_color:value.lab_fancy_color,
                lab_fancy_intensity:value.lab_fancy_intensity,
                lab_fancy_overtone:value.lab_fancy_overtone,
              }
            })
            setCustomerAPI(mapdata)
            setAllRules(apireq.data.data)
        }
    }).
    catch(error => {
      if(error?.response?.data?.message === "UnAuthorized!"){
        var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        if(cookies[i] && !cookies[i].includes("view")){
          var equals = cookies[i].indexOf("=");
          var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
      }
      
      window.location.replace(`${BASE_URL}/logout`);
    }
    })
    })
  },[activeStep])
  React.useEffect(() => {
    formikRef?.current?.validateForm()
  }, [uservalues,customerapi,allrules]);
  const [uservalues,setUserValues] = useState({})
  const currentValidationSchema = ValidationSchema(uservalues,customerapi,allrules)[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  // console.log(initialValues,"initialValues")
  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    console.log(values,"valuesvalues")
    // actions.setSubmitting(false);
    let diamondtype = "N"
    let naturaldiamond = 0
    let naturalfancydiamond = 0
    let lgwhitediam = 0
    let lgfancydiam = 0
    let diamond_type = [values.naturaldiamond?1:0,values.naturalfancydiamond?1:0,values.lgwhitediam?1:0,values.lgfancydiam?1:0]
    // console.log(diamond_type,"diamond_type")
    if(values.naturaldiamond){
      diamondtype = "N"
      naturaldiamond = 1
    }
    if(values.naturalfancydiamond){
      diamondtype = "N"
      naturalfancydiamond = 1
    }
    if(values.lgwhitediam){
      diamondtype = "L"
      lgwhitediam = 1
    }
    if(values.lgfancydiam){
      diamondtype = "L"
      lgfancydiam = 1
    }
    const userData = JSON.parse(localStorage.getItem("userData"))
    console.log(location.state.api_id,"location.state.api_id")
    let FinalObject = {
      diamond_type:diamondtype,
      naturaldiamond:naturaldiamond,
      naturalfancydiamond:naturalfancydiamond,
      labdiamond:lgwhitediam,
      labfancydiamond:lgfancydiam,
      rule_name:values.rulename,
      user_id:props.userinfo.id,
      suppliers:values.suppliers,
      api_id:location.state.api_id,
      perctinclusive:values.perctinclusive?1:0
    }
    FinalObject["rulemarkups"] = values.caratpricemarkups.map(value => {
      console.log(value,"VALUE")
      console.log((parseFloat(value.fromrange) + parseFloat(value.markupvalue)))
      let markupfromrange = 0
      let markuptorange = 0
      if(values.markupoption === "Price"){
        if(value.markuptype === "Absolute"){
          markupfromrange = Math.round((parseFloat(value.fromrange) + parseFloat(value.markupvalue)) * 100)/100
          markuptorange = Math.round((parseFloat(value.torange) + parseFloat(value.markupvalue)) * 100)/100
        }
        else{
          markupfromrange = Math.round((parseFloat(value.fromrange) + (parseFloat(value.fromrange) * parseFloat(value.markupvalue)/100)) * 100)/100
          markuptorange = Math.round((parseFloat(value.torange) + (parseFloat(value.torange) * parseFloat(value.markupvalue)/100)) * 100)/100
        }
      }
      if(FinalObject["diamond_type"] === "N"){
        if(values.markupoption === "Carat"){
          if(value.markuptype === "Absolute"){
            markupfromrange = Math.round((parseFloat(values.total_price_from) + parseFloat(value.markupvalue)) * 100)/100
            markuptorange = Math.round((parseFloat(values.total_price_to) + parseFloat(value.markupvalue)) * 100)/100
          }
          else{
            markupfromrange = Math.round((parseFloat(values.total_price_from) * (1 + parseFloat(value.markupvalue)/100)) * 100)/100
            markuptorange = Math.round((parseFloat(values.total_price_to) * (1 + parseFloat(value.markupvalue)/100)) * 100)/100
          }
        }
      }
      else{
        if(values.markupoption === "Carat"){
          if(value.markuptype === "Absolute"){
            markupfromrange = Math.round((parseFloat(values.lab_total_price_from) + parseFloat(value.markupvalue)) * 100)/100
            markuptorange = Math.round((parseFloat(values.lab_total_price_to) + parseFloat(value.markupvalue)) * 100)/100
          }
          else{
            markupfromrange = Math.round((parseFloat(values.lab_total_price_from) * (1 + parseFloat(value.markupvalue)/100)) * 100)/100
            markuptorange = Math.round((parseFloat(values.lab_total_price_to) * (1 + parseFloat(value.markupvalue)/100)) * 100)/100
          }
        }
      }
      return {
        ...value,
        markupname:values.markupoption,
        markupfromrange:markupfromrange,
        markuptorange:markuptorange
      }
    })
    //FinalObject["currency"] = values.multi_currency
    FinalObject["markupname"] = values.markupoption
    function reorderArray(array1, array2) {
      return array1.filter(item => array2.includes(item));
    }
    const shapeNames = [ "ROUND", "PEAR", "OVAL", "MARQUISE", "HEART", "RADIANT", "PRINCESS","EMERALD", "ASSCHER", "SQUARE RADIANT", "CUSHION BRILLIANT",  "CUSHION MODIFIED", "BAGUETTE", "EUROPEAN CUT",  "OLD MINER", "BRIOLETTE", "BULLETS","CADILLAC", "CALF","CIRCULAR BRILLIANT","CRISS","EPAULETTE","FLAME",  "FLANDERS", "HALF MOON", "HEXAGON",  "KITE", "LOZENGE", "OCTAGON", "PENTAGON", "ROSE", "SHIELD",  "SQUARE", "STAR",  "TAPERED BAGUETTE", "TAPERED BULLET", "TRAPEZOID",  "TRIANGLE", "TRILLIANT"];
    const Color = ["D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "OP", "QR", "ST", "UV", "WX", "YZ"]
    const Cut = ["ID", "EX", "VG", "GD", "FR", "PR"]
    const Clarity = ["FL", "IF", "VVS1", "VVS2", "VS1", "VS2", "SI1", "SI2", "SI3", "I1", "I2", "I3"]
    const Lab = ["GIA", "IGI", "HRD", "AGS"]
    const Polish = ["EX", "VG", "GD", "FR", "PR"]
    const Fluorescence = ["NON", "FNT", "VSLT", "SLT", "MED", "STG", "VST"]
    const Shade = ["NONE", "BROWN", "GREEN", "GREY", "BLACK", "PINK", "BLUE", "LIGHT BROWN", "LIGHT GREEN", "MIX TINGE", "UNKNOWN"]
    const Milky = ["NO MILKY", "LIGHT MILKY", "MILKY", "UNKNOWN"]
    const Eyeclean = ["YES", "NO", "UNKNOWN"]
    const FancyColor = ["YELLOW", "PINK", "BLUE", "RED", "GREEN", "PURPLE", "ORANGE", "VIOLET", "GREY", "BLACK", "BROWN", "CHAMPAGNE", "COGNAC", "CHAMELEON", "WHITE"]
    const FancyIntensity = ["FAINT", "VERY LIGHT", "LIGHT", "FANCY LIGHT", "FANCY", "FANCY DARK", "FANCY INTENSE", "FANCY VIVID", "FANCY DEEP"]
    const FancyOvertone = ["NONE", "YELLOW", "Yellowish", "Pink", "Pinkish", "Blue", "Blueish", "Red", "Reddish", "Green", "Greenish", "Purple", "Purplish", "Orange", "Orangy", "Violet", "Violetish", "Gray", "Grayish", "Black", "Brown", "BROWNISH", "Champagne", "Cognac", "Chameleon", "WHITE"]
    const Availibiltity = ["Guaranteed","Memo,Hold","Subject to Prior sale"]

    if(FinalObject["diamond_type"] === "N"){
        FinalObject["shape"] = reorderArray(shapeNames,values.diamondshape)
        FinalObject["cut"] = reorderArray(Cut,values.cuts)
        FinalObject["clarity"] = reorderArray(Clarity,values.diamondclarity)
        FinalObject["min_carat"] = parseFloat(values.carat_from)
        FinalObject["max_carat"] = parseFloat(values.carat_to)
        FinalObject["lab"] = reorderArray(Lab,values.lab_val)
        FinalObject["symmetry"] = reorderArray(Polish,values.symmetries)
        FinalObject["fluorescence"] = reorderArray(Fluorescence,values.diamondflour)
        FinalObject["polish"] = reorderArray(Polish,values.polishes)
        FinalObject["milky"] = reorderArray(Milky,values.milky)
        FinalObject["eyeclean"] = reorderArray(Eyeclean,values.eyeclean)
        FinalObject["shade"] = reorderArray(Shade,values.diamondshade)
        FinalObject["min_dollarperct"] = parseFloat(values.price_from)
        FinalObject["max_dollarperct"] = parseFloat(values.price_to)
        FinalObject["total_price_from"] = parseFloat(values.total_price_from)
        FinalObject["total_price_to"] = parseFloat(values.total_price_to)
        FinalObject["media"] = values.media
        FinalObject["minlength"] = parseFloat(values.measurementlengthfrom)
        FinalObject["maxlength"] = parseFloat(values.measurementlengthto)
        FinalObject["minwidth"] = parseFloat(values.measurementwidthfrom)
        FinalObject["maxwidth"] = parseFloat(values.measurementwidthto)
        FinalObject["minheight"] = parseFloat(values.measurementheightfrom)
        FinalObject["maxheight"] = parseFloat(values.measurementheightto)
        FinalObject["tablemin"] = parseFloat(values.tablefrom)
        FinalObject["tablemax"] = parseFloat(values.tableto)
        FinalObject["depthmin"] = parseFloat(values.depthfrom)
        FinalObject["depthmax"] = parseFloat(values.depthto)
        FinalObject["ratiomin"] = parseFloat(values.ratiofrom)
        FinalObject["ratiomax"] = parseFloat(values.ratioto)
        FinalObject["crheightmin"] = parseFloat(values.crownheightfrom)
        FinalObject["crheightmax"] = parseFloat(values.crownheightto)
        FinalObject["cranglemin"] = parseFloat(values.crownanglefrom)
        FinalObject["cranglemax"] = parseFloat(values.crownangleto)
        FinalObject["pavheightmin"] = parseFloat(values.pavheightfrom)
        FinalObject["pavheightmax"] = parseFloat(values.pavheightto)
        FinalObject["pavanglemin"] = parseFloat(values.pavanglefrom)
        FinalObject["pavanglemax"] = parseFloat(values.pavangleto)
        FinalObject["brand"] = values.brand
        FinalObject["origin"] = values.origin
        FinalObject["treatment"] = values.treatment
        FinalObject["keytosymbol"] = values.keytosymbol
        FinalObject["availability"] = reorderArray(Availibiltity,values.availability)
        FinalObject["location"] = values.diamondlocation
        if(naturaldiamond){
          FinalObject["color"] = reorderArray(Color,values.diamondcolor)
        }
        else{
          FinalObject["diamondfancy_color"] = reorderArray(FancyColor,values.diamondfancy_color)
          FinalObject["diamondfancy_intensity"] = reorderArray(FancyIntensity,values.diamondfancy_intensity)
          FinalObject["diamondfancy_overtone"] = reorderArray(FancyOvertone,values.diamondfancy_overtone)
        }
    }
    else{
      FinalObject["lab_shape"] = reorderArray(shapeNames,values.lab_shape)
      FinalObject["lab_cut"] = reorderArray(Cut,values.lab_cuts)
      FinalObject["lab_clarity"] = reorderArray(Clarity,values.lab_clarity)
      FinalObject["lab_min_carat"] = parseFloat(values.lab_carat_from)
      FinalObject["lab_max_carat"] = parseFloat(values.lab_carat_to)
      FinalObject["lab_lab"] = reorderArray(Lab,values.lab_lab)
      FinalObject["lab_symmetry"] = reorderArray(Polish,values.lab_symmetries)
      FinalObject["lab_fluorescence"] = reorderArray(Fluorescence,values.lab_flourselect)
      FinalObject["lab_polish"] = reorderArray(Polish,values.lab_polishes)
      FinalObject["lab_milky"] = reorderArray(Milky,values.lab_milky)
      FinalObject["lab_eyeclean"] = reorderArray(Eyeclean,values.lab_eyeclean)
      FinalObject["lab_shade"] = reorderArray(Shade,values.lab_shadeselect)
      FinalObject["lab_min_dollarperct"] = parseFloat(values.lab_min_dollarperct)
      FinalObject["lab_max_dollarperct"] = parseFloat(values.lab_max_dollarperct)
      FinalObject["lab_total_price_from"] = parseFloat(values.lab_total_price_from)
      FinalObject["lab_total_price_to"] = parseFloat(values.lab_total_price_to)
      FinalObject["lab_media"] = values.lab_all_media
      FinalObject["labminlength"] = parseFloat(values.labmeasurementlengthfrom)
        FinalObject["labmaxlength"] = parseFloat(values.labmeasurementlengthto)
        FinalObject["labminwidth"] = parseFloat(values.labmeasurementwidthfrom)
        FinalObject["labmaxwidth"] = parseFloat(values.labmeasurementwidthto)
        FinalObject["labminheight"] = parseFloat(values.labmeasurementheightfrom)
        FinalObject["labmaxheight"] = parseFloat(values.labmeasurementheightto)
        FinalObject["labtablemin"] = parseFloat(values.labtablefrom)
        FinalObject["labtablemax"] = parseFloat(values.labtableto)
        FinalObject["labdepthmin"] = parseFloat(values.labdepthfrom)
        FinalObject["labdepthmax"] = parseFloat(values.labdepthto)
        FinalObject["labratiomin"] = parseFloat(values.labratiofrom)
        FinalObject["labratiomax"] = parseFloat(values.labratioto)
        FinalObject["labcrheightmin"] = parseFloat(values.labcrownheightfrom)
        FinalObject["labcrheightmax"] = parseFloat(values.labcrownheightto)
        FinalObject["labcranglemin"] = parseFloat(values.labcrownanglefrom)
        FinalObject["labcranglemax"] = parseFloat(values.labcrownangleto)
        FinalObject["labpavheightmin"] = parseFloat(values.labpavheightfrom)
        FinalObject["labpavheightmax"] = parseFloat(values.labpavheightto)
        FinalObject["labpavanglemin"] = parseFloat(values.labpavanglefrom)
        FinalObject["labpavanglemax"] = parseFloat(values.labpavangleto)
        // FinalObject["brand"] = values.brand
        FinalObject["laborigin"] = values.laborigin
        FinalObject["labtreatment"] = values.labtreatment
        FinalObject["labkeytosymbol"] = values.labkeytosymbol
        FinalObject["labavailability"] = reorderArray(Availibiltity,values.labavailability)
        FinalObject["lab_location"] = values.diamondlablocation
      if(lgwhitediam){
        FinalObject["lab_color"] = reorderArray(Color,values.lab_color)
      }
      else{
        FinalObject["lab_fancy_color"] = reorderArray(FancyColor,values.lab_fancy_color)
        FinalObject["lab_fancy_intensity"] = reorderArray(FancyIntensity,values.lab_fancy_intensity)
        FinalObject["lab_fancy_overtone"] = reorderArray(FancyOvertone,values.lab_fancy_overtone)
      }
    }
    // console.log(FinalObject,"FinalObject")
  let activityObject = {...FinalObject};
  delete activityObject['rulemarkups']
  delete activityObject['naturaldiamond']
  delete activityObject['naturalfancydiamond']
  delete activityObject['labdiamond']
  delete activityObject['labfancydiamond']
  delete activityObject['suppliers']

  if (FinalObject['naturaldiamond'] ){
    activityObject["sub_diamond_type"]= "N";
  }
  if (FinalObject['naturalfancydiamond']){
    activityObject["sub_diamond_type"]= "NF";
  }
  if (FinalObject['labdiamond']){
    activityObject["sub_diamond_type"]= "L";
  }
  if (FinalObject['labfancydiamond']){
    activityObject["sub_diamond_type"]= "LF";
  }

  axios({
      method: "POST",
      url: `${NODE_API_URL}/INTL/insert-or-update-ccmode-rules`,
      headers: {
        "Content-Type": "application/json",
         "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
    },
      data: FinalObject,
  }).then(res => {
      if(res && res.data && res.data.data){
          // setData(res.data)
          // console.log( res.data,"NOICE")
          if(res.data.data === "Rule Inserted Successfully!" || res.data.data === "Rule Updated Successfully!"){
            // window.location.replace('/ccmode')
            history.push({
              pathname: "/ccmode",
              state: {
                updatestatus:res.data.data === "Rule Updated Successfully!"
              },
            })

            axios({
              method: "POST",
              url: `${NODE_API_URL}/INTL/store-ruleACtivity`,
              headers: { 
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
              },
              data: {
                  // "ip_address":"8.8.8.8",
                  "diamond_type":FinalObject["diamond_type"],
                  "activity_type":res.data.data === "Rule Inserted Successfully!" ? "RuleCreated" : "RuleUpdated",
                  "user_agent":navigator.userAgent,
                  "activity_data":activityObject
              },
            }).then(response => {
            }).catch(error => {
              if(error?.response?.data?.message === "UnAuthorized!"){
                var cookies = document.cookie.split(";");
              for (var i = 0; i < cookies.length; i++) {
                if(cookies[i] && !cookies[i].includes("view")){
                  var equals = cookies[i].indexOf("=");
                  var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
              }
              
              window.location.replace(`${BASE_URL}/logout`);
            }
            })
            
          }
      }
  }).catch(error => {
    if(error?.response?.data?.message === "UnAuthorized!"){
      var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      if(cookies[i] && !cookies[i].includes("view")){
        var equals = cookies[i].indexOf("=");
        var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    }
    
    window.location.replace(`${BASE_URL}/logout`);
  }
  })
    // const FinalValues = {
    //   ...values,
    //   diamond_type:diamondtype,
    //   "user_id":userData.CustomerId,
    //   fancycolor:values.diamondfancy_color,
    //   fancyintensity:values.diamondfancy_intensity,
    //   fancyovertone:values.diamondfancy_overtone,
    //   labfancycolor:values.lab_fancy_color,
    //   labfancyintensity:values.lab_fancy_intensity,
    //   labfancyovertone:values.lab_fancy_overtone,
    //   lab_flour:values.lab_flourselect,
    //   lab_shade:values.lab_shadeselect,
    //   secondarycolor:values.secondary,
    //   min_dollarperct:values.price_from,
    //   max_dollarperct:values.price_to,
    //   "embeded_code_option":"Separate",
    //   lab_media:values.lab_all_media
    // }
    // console.log(FinalValues,"FinalValues")
    // console.log(values,"valuesvalues")
    // console.log(FinalValues,"FinalValues")
  //   axios({
  //     method: "POST",
  //     url: `${NODE_API_URL}/INTL/insertIframeData`,
  //     headers: { "Content-Type": "application/json" },
  //     data: FinalValues,
  // }).then(res => {
  //     if(res && res.data){
  //         // setData(res.data)
  //         // console.log( res.data,"NOICE")
  //         window.location.reload(true)
  //     }
  // })
    // const formData = new FormData();
    // window.location.reload(true)
    // if(values.wl_primarycolor){
    //   formData.append("primarycolor", values.primarycolor)
    // }
    // if(values.n_fontcolor){
    //   formData.append("secondary", values.secondary)
    // }
    // if(values.lab_primary){
    //   formData.append("fontcolor", values.fontcolor)
    // }
  }

  function _handleSubmit(values, actions) {
    setUserValues(values)
    console.log("HANDLE SUBMIT")
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      console.log("ELSE PART")
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }
 const [prev,setPrev] = useState(false)
  function _handleBack(setFieldValue) {
    setActiveStep(activeStep - 1);
    setPrev(true)
    setSupplierChanged(false)
    setFieldValue("showmarkup",false)
    setValidMarkup(false)
    setSupplierData([])
  }
  const [selectallfields,setSelectAllFields] = useState(false)
  const [selectallfieldslab,setSelectAllFieldsLab] = useState(false)

  // console.log(selectallfields,"selectallfields")
  const SaveSuppliers = () => {
    let promises = []
    for (let i = 0; i < supplierdata.length; i++) {
      promises.push(
            axios({
        method: "POST",
        url: `${NODE_API_URL}/INTL/updateSupplierRuleOnOff`,
        headers: {
          "Content-Type": "application/json",
           "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
      },
        data: {
          rule_id:supplierdata[i].rule_id,
          on_off:supplierdata[i].on_off,
          supplier_name:supplierdata[i].supplier_name
        },
    }).then(supreq => {
        if(supreq && supreq.data){
            return supreq.data
        }
    })
      )
    }
    Promise.all(promises).then(() => {
      toast.success("Your Feeds are Updated!")
      setSupplierChanged(false)
      setSupplierData([])
      setupdateApi(!updateapi)
    }).catch(error => {
      if(error?.response?.data?.message === "UnAuthorized!"){
        var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        if(cookies[i] && !cookies[i].includes("view")){
          var equals = cookies[i].indexOf("=");
          var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
      }
      
      window.location.replace(`${BASE_URL}/logout`);
    }
    })
    // axios({
    //     method: "POST",
    //     url: `${NODE_API_URL}/INTL/updateDataFromSupReqable`,
    //     headers: { "Content-Type": "application/json" },
    //     data: {
    //         "fields": [
    //             {
    //                 "api_on_off": switchvalue
    //             }
    //         ],
    //         "condition": [
    //             {
    //                 "sr_id": d.sr_id
    //             }
    //         ]
    //     },
    // }).then(supreq => {
    //     if(supreq && supreq.data){
    //         if(supreq.data){
    //         setUpdate(d)
    //         if(switchvalue === 1){
    //             toast.success(`Your Feed Turned on for ${d.supplier_name_o}`)
    //         }else{
    //             toast.error(`Your Feed Turned off for ${d.supplier_name_o}`)
    //         }
    //         }
    //     }
    // })
  }
  const CancelSuppliers = ()=> {
    setSupplierChanged(false)
    setSupplierData([])
    setupdateApi(!updateapi)
  }
  return (
    <React.Fragment>
      {activeStep === steps.length ? (
          <></>
        ) : (
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
      <React.Fragment>
        {activeStep === steps.length ? (
          <FormSuccess />
        ) : (
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
            innerRef={formikRef}
          >
            {({ isSubmitting,setFieldValue, values,setValues }) => (
              <Form id={formId} className='bg-white'>
                          
               <div className={activeStep === 4 && !allsupplierdata.length?"p-5 bg-gray-100":"p-5"} style={{minHeight: "60vh"}}>{_renderStepContent(activeStep,setFieldValue,prev,setPrev,values,props.userinfo,selectallfields,selectallfieldslab,props.language,setValues,location,setSelectAllFields,setSelectAllFieldsLab,setSupplierChanged,setSupplierData,supplierdata,updateapi,setValidMarkup,setAllSupplierData)}</div>
                <Grid className="mt-10 sticky-top bg-gray-100  shadow-bottom" container justify={"space-between"} alignItems="center"  style={{bottom:"0"}}>
                  <div className="ml-2">
                  {activeStep !== 0 && activeStep !== 4 && (
                    <Button color="primary" onClick={() => _handleBack(setFieldValue)} className={classes.button}>
                      <FaChevronLeft className="mr-1" /> <span className="mt-1">{props.language.PROFILE_PREVIOUS}</span >
                    </Button>
                  )}
                  {
                      activeStep !== 4?
                      <Button 
                      onClick={() => window.location.replace('/ccmode')} 
                      className={`btn btn-danger`}
                      >
                         Cancel Rule
                    </Button>:""
                    }
                    </div>
                    {formikRef?.current?.errors?.overlap && <span className="text-danger font-weight-bold">{formikRef?.current?.errors?.overlap}</span>}
                  <div className={classes.wrapper}>
                      {activeStep === 2 && (values.naturaldiamond || values.naturalfancydiamond)?<Button
                        disabled={isSubmitting}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => setSelectAllFields(true)}>Select Required</Button>:""}
                        {activeStep === 2 && (values.lgwhitediam || values.lgfancydiam)?<Button
                        disabled={isSubmitting}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => setSelectAllFieldsLab(true)}>Select Required</Button>:""}
                    {activeStep === 4 && !allsupplierdata.length?<Button
                      // disabled={isSubmitting || (isLastStep && values.caratpricemarkups.length === 0)}
                      // type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={() => window.location.replace('/supplier-management')}
                    >
                      Supplier Management
                    </Button>:""}
                    {validmarkup && values.markupoption && (
                      <span className="text-center text-warning font-weight-bold">
                        Please cover {values.markupoption} range from{" "}
                        {values.markupoption === "Price"
                          ? (values.naturaldiamond || values.naturalfancydiamond)
                            ? values.total_price_from
                            : values.lab_total_price_from
                          : (values.naturaldiamond || values.naturalfancydiamond)
                          ? values.carat_from
                          : values.lab_carat_from}{" "}
                        to{" "}
                        {values.markupoption === "Price"
                          ? (values.naturaldiamond || values.naturalfancydiamond)
                            ? values.total_price_to
                            : values.lab_total_price_to
                          : (values.naturaldiamond || values.naturalfancydiamond)
                          ? values.carat_to
                          : values.lab_carat_to}
                        .
                      </span>
                    )}
                    {activeStep === 4 && supplierchanged?<>
                        <Button
                      // disabled={isSubmitting || (isLastStep && values.caratpricemarkups.length === 0)}
                      // type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={SaveSuppliers}
                    >
                      Save
                    </Button>
                    <ErrorButton
                      // disabled={isSubmitting || (isLastStep && values.caratpricemarkups.length === 0)}
                      // type="submit"
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={CancelSuppliers}
                    >
                      Cancel
                    </ErrorButton>
                    </>:activeStep === 4?<Button
                     variant="contained"
                     color="primary"
                     className={classes.button}
                     onClick={() => window.location.replace('/ccmode')}
                     disabled={!allsupplierdata.length}
                     >
                      Save Rule
                    </Button>:""}
                    {!supplierchanged && activeStep !== 4?<Button
                      disabled={isSubmitting || (isLastStep && values.caratpricemarkups.length === 0) || (values.showmarkup) || (validmarkup)}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {isLastStep ? "Save Rule" : props.language.PROFILE_NEXT}
                    </Button>:""}
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}
